import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalization } from 'gatsby-theme-i18n';
import PropTypes from 'prop-types';
import CertificateIcon from '../images/certificate-icon.svg';
import FileIcon from '../images/file-icon.svg';
import PageIcon from '../images/page-icon.svg';
import ContractIcon from '../images/contract-icon.svg';
import SchoolIcon from '../images/school-icon.svg';
import Layout from '../layouts/index';
import DemoForm from '../components/DemoForm';
import Slider from '../components/Slider';

function TratoEducation({ location }) {
  const { t } = useTranslation();
  const { locale } = useLocalization();

  return (
    <Layout
      id="landing"
      locale={locale}
      bodyClass="page-home landing bg-grey trato-education"
      inverted
      border
      simpleLayout
      location={location}
    >
      <section className="bg-grey-xx">
        <div className="container">
          <div className="row mt-8">

            {/* left section */}
            <div className="col-lg-7">

              {/* section 1 */}
              <div className="row">
                <div className=" p-2 w-100">
                  <h2 className="font-weight-bold mb-2">
                    {t('tratoEducation.secondHeroTitle')}
                  </h2>
                  <span className="font-weight-bold text-muted">
                    {t('tratoEducation.secondSubtitle')}
                  </span>
                  <div className=" d-flex justify-content-center">
                    <img className="w-100 mt-3 mb-3" src={SchoolIcon} alt="school" />
                  </div>
                </div>
              </div>

              {/* section 2 */}
              <div className="row">
                <h2 className="font-weight-bold text-center ">
                  {t('tratoEducation.sectionOneTitle')}
                </h2>
                <div className="mt-4 d-flex flex-wrap justify-content-around">
                  <div className="circle-svg d-flex flex-wrap justify-content-center align-items-center">
                    <div className="circle-transparent d-flex flex-wrap justify-content-center">
                      <p className="font-weight-bold text-muted text-center number">1</p>
                      <p className="font-weight-bold text-muted text-center text">
                        {t('tratoEducation.secondFeature1')}
                      </p>
                    </div>
                    <div className="w-100 d-flex justify-content-center">
                      <svg height="20" width="20">
                        <polygon points="10,0 0,18 20,18" stroke="#F47B33" fill="#F47B33" />
                      </svg>
                    </div>
                  </div>
                  <div className="circle-svg d-flex flex-wrap justify-content-center align-items-center">
                    <div className="circle-transparent d-flex flex-wrap justify-content-center">
                      <p className="font-weight-bold text-muted text-center number">2</p>
                      <p className="font-weight-bold text-muted text-center text">
                        {t('tratoEducation.secondFeature2')}
                      </p>
                    </div>
                    <div className="w-100 d-flex justify-content-center">
                      <svg height="20" width="20">
                        <polygon points="10,0 0,18 20,18" stroke="#F47B33" fill="#F47B33" />
                      </svg>
                    </div>
                  </div>
                  <div className="circle-svg d-flex flex-wrap justify-content-center align-items-center">
                    <div className="circle-transparent d-flex flex-wrap justify-content-center">
                      <p className="font-weight-bold text-muted text-center number">3</p>
                      <p className="font-weight-bold text-muted text-center text">
                        {t('tratoEducation.secondFeature3')}
                      </p>
                    </div>
                    <div className="w-100 d-flex justify-content-center">
                      <svg height="20" width="20">
                        <polygon points="10,0 0,18 20,18" stroke="#F47B33" fill="#F47B33" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              {/* section 3 */}
              <div className="row">
                <div className="align-self-center pt-3 pr-3 mt-5">
                  <h5 className="font-weight-bold mb-3">{t('tratoEducation.sectionTwoTitle')}</h5>
                  <ul className="list-unstyled">
                    <li className="d-flex mb-4">
                      <div className="d-flex justify-content-center p-2 bg-light circle-orange-border mr-2">
                        <div className="d-flex">
                          <img alt="" src={CertificateIcon} className="text-primary align-baseline icon-lg align-self-center" />
                        </div>
                      </div>
                      <div>
                        <span className="font-weight-normal text-muted">{t('tratoEducation.feature1')}</span>
                      </div>
                    </li>
                    <li className="d-flex mb-4">
                      <div className="d-flex justify-content-center p-2 bg-light circle-orange-border mr-2">
                        <div className="d-flex">
                          <img alt="" src={FileIcon} className="text-primary align-baseline icon-lg align-self-center" />
                        </div>
                      </div>
                      <div>
                        <span className="font-weight-normal text-muted">{t('tratoEducation.feature2')}</span>
                      </div>
                    </li>
                    <li className="d-flex mb-4">
                      <div className="d-flex justify-content-center p-2 bg-light circle-orange-border mr-2">
                        <div className="d-flex">
                          <img alt="" src={PageIcon} className="text-primary align-baseline icon-lg align-self-center" />
                        </div>
                      </div>
                      <div>
                        <span className="font-weight-normal text-muted">{t('tratoEducation.feature3')}</span>
                      </div>
                    </li>
                    <li className="d-flex mb-4">
                      <div className="d-flex justify-content-center p-2 bg-light circle-orange-border mr-2">
                        <div className="d-flex">
                          <img alt="" src={ContractIcon} className="text-primary align-baseline icon-lg align-self-center" />
                        </div>
                      </div>
                      <div>
                        <span className="font-weight-normal text-muted">{t('tratoEducation.feature4')}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              {/* section 4 */}
              {/* <div className="row">
                <Slider
                  sliderTitle={t('tratoEducation.testimonialTitle')}
                  elements={[
                    {
                      text: t('tratoEducation.testimonialOneText'),
                      company: t('tratoEducation.testimonialOneCompany'),
                    },
                  ]}
                />
              </div> */}
            </div>

            {/* right section */}
            <div className="col-lg-5">
              <div className="form sticky-form p-5 sticky-top mt-2">
                <div className="d-flex justify-content-center mb-2">
                  <span className="text-center font-weight-bold text-muted">{t('demoForm.subtitle')}</span>
                </div>
                <DemoForm onlyForm formButtonLabel={t('demoForm.button3')} type="trato-education" />
              </div>
            </div>

          </div>
        </div>
      </section>
    </Layout>
  );
}

TratoEducation.propTypes = {
  location: PropTypes.any,
};

export default TratoEducation;
